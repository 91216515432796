<template>
    <div class="my_treat_opr_page" v-wechat-title="this.title">
        <div class="title_info">
            <div>当前医生</div>
            <div>{{doctorname}}</div>
        </div>
        <div class="modify_info">
            <div class="title">修改医生</div>
            <div class="list_div">
                <van-grid :gutter="10" :column-num="3">
                    <van-grid-item @click="changeDoctor(index, $event)" v-for="(item, index) in doctorList" :key="item.account" :account="item.account" :class="item.account == newdoctor ? 'active' : ''" icon="" :text="item.name" />
                </van-grid>
            </div>
        </div>
        <div class="modify_info">
            <div class="title">执行诊室</div>
            <div class="list_div">
                <van-grid :gutter="10" :column-num="3">
                    <van-grid-item @click="changeRoom(index, $event)" v-for="(item, index) in roomList" :key="item.roomid" :roomid="item.roomid" :class="item.roomid == newroom ? 'active' : ''" icon="" :text="item.roomname" />
                </van-grid>
            </div>
        </div>
        <div class="modify_info" v-if="oprtype === '3'">
            <div class="title">治疗备注</div>
            <div class="remark_div">
                <van-field
                        v-model="remark"
                        rows="3"
                        autosize
                        label=""
                        type="textarea"
                        maxlength="100"
                        placeholder="请输入治疗备注（可填）"
                        show-word-limit
                />
            </div>
        </div>
         <div class="ctrl_bottom" v-if="show">
             <button @click="treatOpr" class="submit_btn">提交</button>
        </div>
        
    </div>
</template>

<script>
    import { wxdoctorqry,wxtreatopr,wxroomqry } from '@/api/medical'
    import { mapGetters } from 'vuex'
    import { Toast } from 'vant'
    export default {
        computed: {
            ...mapGetters([
                'username','clinicid'
            ]),
        },
        data() {
            return {
                oprtype: '0',
                serviceid: '',
                doctor:'',
                remark:'',
                doctorname: '',
                newdoctor: '',
                newroom: '',
                doctorList:[],
                roomList:[],
                selected: 0,
                show: true,
                title: '重新分诊'
            }
        },
        mounted () {
            this.$store.dispatch('hideOrShowNav', false)
            this.serviceid = this.$route.query.serviceid || ''
            this.oprtype = this.$route.query.oprtype || ''
            this.doctor = this.$route.query.doctor || ''
            this.newdoctor = this.$route.query.doctor || ''
            this.doctorname = this.$route.query.doctorname || ''
            this.roomid = this.$route.query.roomid || ''
            this.newroom = this.$route.query.roomid || ''
            this.$route.meta.title = '标题名称'
            if(this.oprtype == '3') {
                this.title = '完成'
            }
            this.wxdoctorqry()
            this.wxroomqry()
        },
        methods: {
            // 获取医生信息
            wxdoctorqry(){
                Toast.loading({
                    message: '加载中...',
                    forbidClick: true,
                })
                let data = {
                    username: this.username,
                    qrydate: '0',
                    clinicid: this.clinicid,
                }
                wxdoctorqry(data).then(response => {
                    Toast.clear()
                    this.doctorList = response.response_body.list
                })
            },
            // 获取医生信息
            wxroomqry(){
                Toast.loading({
                    message: '加载中...',
                    forbidClick: true,
                })
                let data = {
                    username: this.username,
                    qrydate: '0',
                    clinicid: this.clinicid,
                }
                wxroomqry(data).then(response => {
                    Toast.clear()
                    this.roomList = response.response_body.list
                })
            },
            changeDoctor(index, event) {
                this.newdoctor = event.currentTarget.parentElement.getAttribute("account");
                //获取点击对象
                /*console.log(event.currentTarget.parentElement, '父节点');
                console.log(event.currentTarget.parentElement.parentElement.children, '父节点的兄弟节点');
                console.log(event.currentTarget.parentElement.getAttribute("account"));
                console.log(event.currentTarget);
                console.log(event.target);*/
            },
            changeRoom(index, event) {
                this.newroom = event.currentTarget.parentElement.getAttribute("roomid");
            },
            treatOpr() {
                let msg = "确认重新分诊本次项目吗？";
                if(this.oprtype == '3') {
                    msg = "确认是否已完成本次项目？";
                }
                this.$dialog.confirm({
                    title: null,
                    message: msg,
                })
                    .then(() => {
                        let data = {
                            username: this.username,
                            oprtype: this.oprtype,
                            serviceid: this.serviceid,
                            doctor: this.newdoctor,
                            room: this.newroom,
                            remark: this.remark
                        }
                        wxtreatopr(data).then(() => {
                            this.show = false;
                            let tip = "重新分诊成功";
                            if(this.oprtype == '3') {
                                tip = "本次项目已完成";
                            }
                            this.$store.dispatch('changeIsRefresh', '1')
                            Toast.success(tip);
                            let second = 2;
                            const timer = setInterval(() => {
                                second--;
                                if (second === 0) {
                                    clearInterval(timer);
                                    this.$router.go(-1)
                                }
                            }, 1000);
                        })
                    })
                    .catch(() => {
                        // on cancel
                    });
            }
        },
    }
</script>

<style lang="scss" scoped>
    /deep/.van-grid-item .van-grid-item__content {
        padding: 0rem;
        background-color: #E7EBF7;
        margin: 7px 10px;
        font-size: 14px;
        color: #666666;
    }
    .van-cell {
        background-color: #F8F7FC;
        border-radius: 12px;
    }
</style>